// Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.min";
import $ from "jquery";

$(function () {
  $(".c-index-visual").slick({
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    easing: "linear",
    dots: true,
    lazyLoad: "ondemand",
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 1000,
  });
});
